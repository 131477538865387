@charset "UTF-8";

/*================================================
Start Default CSS
=================================================*/

@font-face {
  font-family: 'SquadaOne-Regular';
  src: url('../fonts/SquadaOne-Regular.ttf') format('truetype');
}

:root {
  --fontFamily: 'SquadaOne-Regular', sans-serif;
  --mainColor: #FC674F;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --pinkColor: #DC70FA;
  --blueColor: #3772FF;
  --yellowColor: #FFF4BC;
  --fontSize: 16px;
  --transition: .5s;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
}

.master-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}

/*================================================
End Default CSS
=================================================*/


/*================================================
Start Language Selector CSS
=================================================*/

.mantine-Select-input {
  background: var(--blackColor);
  color: var(--whiteColor);
  text-align: center;
  font-size: 1em;
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 20px;
}

/*================================================
End Language Selector CSS
=================================================*/

/*================================================
  Go Top CSS
=================================================*/
.go-top {
  z-index: 9;
  opacity: 0;
  right: 20px;
  width: 45px;
  height: 45px;
  bottom: 20px;
  position: fixed;
  cursor: pointer;
  font-size: 30px;
  border-radius: 50%;
  visibility: hidden;
  text-align: center;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--whiteColor);
}

.go-top i {
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  bottom: 20px;
  visibility: visible;
}

.go-top:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*================================================
  Toast CSS
=================================================*/

.reapop__notification-title {
  font-size: 16px !important;
}

.reapop__notification-message {
  font-size: 16px !important;
}